<template>
  <div
    class="flex items-center w-64 lg:w-72"
    v-if="blok.date != null && showCountdown"
  >
    <span
      class="text-xxs lg:text-xs leading-5 text-center font-semibold px-4"
      >{{ countdown }}</span
    >
  </div>
</template>

<script setup>
const props = defineProps({
  blok: Object,
})

const countdown = ref('')
const showCountdown = ref(true)

const calculateCountdown = () => {
  if (!props.blok.date == null) return
  const targetDate = new Date(props.blok.date)
  const now = new Date()
  if (targetDate < now) {
    showCountdown.value = false
    return
  }

  let difference = targetDate - now

  if (difference < 0) {
    countdown.value = '0 días 00 horas 00 minutos 00 segundos'
    return
  }

  const days = Math.floor(difference / (1000 * 60 * 60 * 24))
  difference -= days * 1000 * 60 * 60 * 24

  const hours = Math.floor(difference / (1000 * 60 * 60))
  difference -= hours * 1000 * 60 * 60

  const minutes = Math.floor(difference / (1000 * 60))
  difference -= minutes * 1000 * 60

  const seconds = Math.floor(difference / 1000)

  countdown.value = `${days} días ${hours
    .toString()
    .padStart(2, '0')} horas ${minutes
    .toString()
    .padStart(2, '0')} minutos ${seconds.toString().padStart(2, '0')} segundos`
}

calculateCountdown()
onMounted(() => {
  if (!props.blok.date == null) return
  setInterval(() => {
    calculateCountdown()
  }, 1000)
})
</script>
